import styled from 'styled-components';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';

export const AddResumeWrapper = styled.div`
  border: 1px solid ${Colors.GreyLightest};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ViewWrapper = styled.div`
  padding-block-start: 16px;

  ${media.from_tablet`
    padding-block-start: 20px;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
 
 ${media.from_tablet`
    flex-direction: row;
    gap: 16px;
  `};
`;
